// Home.js
import React from "react";

const Home = () => {
  return (
    <div>
      <h1>Welcome to My Game Resource Calculator</h1>
      <p>This is the homepage. Use the menu to navigate to the calculator.</p>
    </div>
  );
};

export default Home;
